<template>
    <div class="sup_content general-settings">
        <div class="sup_page_heading">
            <h2>General Settings</h2>
        </div>
        <div class="sup_inner_content">
            <div class="sup_settings_form">
                <form @submit.prevent="updateSettings()">
                    <div class="card">
                        <h3>Contact Details</h3>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="site_title">Site Title *</label>
                                <InputText id="site_title" v-model="settingFormData.siteTitle" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.siteTitleError">Site Title is not available.</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="site_name">Site Name *</label>
                                <InputText id="site_name" v-model="settingFormData.name" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.nameError">Site Name is not available.</small>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="contact_email">Contact Email *</label>
                                <InputText id="contact_email" v-model="settingFormData.email" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.emailError">Contact Email is not available.</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="contact_number">Contact Number *</label>
                                <InputText id="contact_number" v-model="settingFormData.contactNumber" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.contactNumberError">Contact Number is not available.</small>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
                                <label for="metaTitle">Email Signature *</label>
                               
                                <editor
                                    class="email_signature_editor"
                                    api-key="no-api-key"
                                    :init="{
                                        menubar: true
                                    }"
                                    plugins="code"
                                    toolbar="bold italic underline code"
                                v-model="settingFormData.emailSignature"
                                />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.emailSignatureError">Email Signature is not available.</small>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card">
                        <h3>Service & Payment Details</h3>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="hits_alert">Hits Alert Threshold *</label>
                                <InputText id="hits_alert" v-model="settingFormData.hitsAlertThreshold" type="number" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.hitsAlertThresholdError">Hits Alert Threshold is not available.</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="cookie">Cookie Name Similarity Ratio</label>
                                <InputText id="cookie" v-model="settingFormData.cookieSimilarity" type="number" />
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="ip_rating">IP Rate Limit *</label>
                                <InputText id="ip_rating" v-model="settingFormData.ipRateLimit" type="number" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.ipRateLimitError">IP Rate Limit is not available.</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="ip_rating">IP Rate Frequency *</label>
                                <InputText id="ip_rating" v-model="settingFormData.ipRateFrequency" type="number" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.ipRateFrequencyError">IP Rate Frequency is not available.</small>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="currency">Currency</label>
                                <InputText id="currency" v-model="settingFormData.currency" type="text" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="waveApiCreditRemaining">Wave Api Credit Remaining</label>
                                <InputText id="waveApiCreditRemaining" v-model="settingFormData.waveApiCreditRemaining" type="text" readonly />
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="paymentMethodType" class="p-d-block">Payment Method</label>
                                <InputSwitch id="paymentMethodType" v-model="settingFormData.paymentMethodType" />
                                <span class="sup_payment_mthod">{{settingFormData.paymentMethodType ? 'Live' : 'Sandbox'}}</span>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="deploymentStatus" class="p-d-block">Deployment Status</label>
                                <InputSwitch id="deploymentStatus" v-model="settingFormData.deploymentStatus" />
                                <span class="sup_deployment_status">{{settingFormData.deploymentStatus ? 'True' : 'False'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <h3>SEO Details</h3>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="metaDescription">Meta Title *</label>
                                <InputText id="metaDescription" v-model="settingFormData.metaTitle" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.metaTitleError">Meta Title is not available.</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="metaKeyWords">Meta KeyWords *</label>
                                <InputText id="metaKeyWords" v-model="settingFormData.metaKeyWords" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.metaKeyWordsError">Meta KeyWords is not available.</small>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label for="metaTitle">Meta Description *</label>
                                <Textarea id="metaTitle" v-model="settingFormData.metaDescription" />
                                <small class="p-invalid sup_text_danger" v-if="settingFormValidationState.metaDescriptionError">Meta Description is not available.</small>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label for="googleAnalyticsHeader">Google Analytics Header</label>
                                <Textarea id="googleAnalyticsHeader" v-model="settingFormData.googleAnalyticsHeader" />
                            </div>
                            <div class="p-field p-col-12">
                                <label for="googleAnalyticsFooter">Google Analytics Footer</label>
                                <Textarea id="googleAnalyticsFooter" v-model="settingFormData.googleAnalyticsFooter" />
                            </div>
                        </div>
                    </div>

                   

                    <div class="p-fluid p-d-flex sup_submit_wrap">
                        <Button type="submit" label="Update" class="btn-primary sup_submit p-mr-3" />
                        <Button type="button" label="Reset" class="btn-info sup_submit" @click="resetSettings()" />
                    </div>
                </form>
                <div v-if="settingFormData.loading" class="sup_settings_loading"><i class="pi pi-spin pi-spinner"></i></div>
            </div>
        </div>
        <!-- Toast to show notification of events start -->
        <Toast position="top-right" />
        <!-- Toast to show notification of events end -->
    </div>
</template>

<script>
import { adminSetting } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import axios from 'axios';
import { useStore } from 'vuex';
import { onBeforeMount, reactive } from 'vue';
import { useToast } from "primevue/usetoast";
import Editor from '@tinymce/tinymce-vue';
 
export default {
    name: "GeneralSettings",
    components: {
        'editor': Editor
    },
    setup(){
        //Store call
        let store = useStore();

        //Toast call
        const toast = useToast();

        const settingFormData = reactive({
            contactNumber: '',
            email: '',
            name: '',
            cookieSimilarity: '',
            currency: '',
            gaFooter: '',
            gaHeader: '',
            hitsAlertThreshold: '',
            ipRateFrequency: '',
            ipRateLimit: '',
            metaDescription: '',
            emailSignature:'',
            metaKeyWords: '',
            metaTitle: '',
            paymentMethodType: false,
            deploymentStatus: false,
            siteTitle: '',
            waveApiCreditRemaining: '',
            uuid: '',
            updateStatus: '',
            updateMessage: '',
            loading: false
        });

        const settingFormValidationState = reactive({
            contactNumberError: false,
            emailError: false,
            nameError: false,
            cookieSimilarityError: false,
            hitsAlertThresholdError: false,
            ipRateFrequencyError: false,
            ipRateLimitError: false,
            metaDescriptionError: false,
            emailSignatureError: false,
            metaKeyWordsError: false,
            metaTitleError: false,
            siteTitleError: false,
            deploymentStatusError: false
        });

        //On before mount function invoke
        onBeforeMount(async()=>{
            fetchSettings();
        });

        //Fetch All Language Key
        const fetchSettings = async () =>{
            try{
                settingFormData.loading = !settingFormData.loading;
                const response = await axios.get(adminSetting, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                settingFormData.contactNumber = response.data.data[0].adminContactNumber;
                settingFormData.email = response.data.data[0].adminEmail;
                settingFormData.name = response.data.data[0].adminName;
                settingFormData.cookieSimilarity = response.data.data[0].cookieNameSimilarityRatio;
                settingFormData.currency = response.data.data[0].defaultCurrency;
                settingFormData.gaFooter = response.data.data[0].googleAnalyticsFooter;
                settingFormData.gaHeader = response.data.data[0].googleAnalyticsHeader;
                settingFormData.hitsAlertThreshold = response.data.data[0].hitsAlertThreshold;
                settingFormData.ipRateFrequency = response.data.data[0].ipRateFrequency;
                settingFormData.ipRateLimit = response.data.data[0].ipRateLimit;
                settingFormData.metaDescription = response.data.data[0].metaDescription;
                settingFormData.emailSignature = response.data.data[0].emailSignature;
                settingFormData.metaKeyWords = response.data.data[0].metaKeyWords;
                settingFormData.metaTitle = response.data.data[0].metaTitle;
                settingFormData.paymentMethodType = response.data.data[0].paymentMethodType === "sandbox" ? false : true;
                settingFormData.siteTitle = response.data.data[0].siteTitle;
                settingFormData.waveApiCreditRemaining = response.data.data[0].waveApiCreditRemaining;
                settingFormData.uuid = response.data.data[0].uuid;
                settingFormData.deploymentStatus = response.data.data[0].deploymentStatus;
                settingFormData.loading = !settingFormData.loading;

            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const updateSettings = async() => {
            let validate = settingFormValidation();

            if (validate) {
                try{
                    const options = {
                        method: 'put',
                        url: adminSetting,
                        params: {
                            uuid: settingFormData.uuid
                        },
                        data: JSON.stringify({
                            siteTitle: settingFormData.siteTitle,
                            metaTitle: settingFormData.metaTitle,
                            metaKeyWords: settingFormData.metaKeyWords,
                            metaDescription: settingFormData.metaDescription,
                            emailSignature: settingFormData.emailSignature,
                            adminName: settingFormData.name,
                            adminEmail: settingFormData.email,
                            adminContactNumber: settingFormData.contactNumber,
                            hitsAlertThreshold: settingFormData.hitsAlertThreshold,
                            paymentMethodType: settingFormData.paymentMethodType === true ? 'live' : 'sandbox',
                            ipRateLimit: settingFormData.ipRateLimit,
                            ipRateFrequency: settingFormData.ipRateFrequency,
                            cookieNameSimilarityRatio: settingFormData.cookieSimilarity,
                            deploymentStatus: settingFormData.deploymentStatus
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    const response = await axios(options);
                    if (response.data.status === 200) {
                        toast.add({severity:'success', summary: 'settings Updated', detail: response.data.message, life: 5000});
                        // settingFormData.updateStatus = response.data.status;
                        // settingFormData.updateMessage = response.data.message;
                        //fetchSettings();
                    }
                } catch(err){
                    settingFormData.updateStatus = err.response.data.status;
                    settingFormData.updateMessage = err.response.data.message;
                }
            }
        }

        const settingFormValidation = () => {
            if (settingFormData.contactNumber === '') {
                settingFormValidationState.contactNumberError = true;
            } else {
                settingFormValidationState.contactNumberError = false;
            }

            if (settingFormData.email === '') {
                settingFormValidationState.emailError = true;
            } else {
                settingFormValidationState.emailError = false;
            }

            if (settingFormData.name === '') {
                settingFormValidationState.nameError = true;
            } else {
                settingFormValidationState.nameError = false;
            }

            if (settingFormData.cookieSimilarity === '') {
                settingFormValidationState.cookieSimilarityError = true;
            } else {
                settingFormValidationState.cookieSimilarityError = false;
            }

            if (settingFormData.hitsAlertThreshold === '') {
                settingFormValidationState.hitsAlertThresholdError = true;
            } else {
                settingFormValidationState.hitsAlertThresholdError = false;
            }

            if (settingFormData.ipRateFrequency === '') {
                settingFormValidationState.ipRateFrequencyError = true;
            } else {
                settingFormValidationState.ipRateFrequencyError = false;
            }

            if (settingFormData.ipRateLimit === '') {
                settingFormValidationState.ipRateLimitError = true;
            } else {
                settingFormValidationState.ipRateLimitError = false;
            }

            if (settingFormData.metaDescription === '') {
                settingFormValidationState.metaDescriptionError = true;
            } else {
                settingFormValidationState.metaDescriptionError = false;
            }

            if (settingFormData.emailSignature === '') {
                settingFormValidationState.emailSignatureError = true;
            } else {
                settingFormValidationState.emailSignatureError = false;
            }

            if (settingFormData.metaKeyWords === '') {
                settingFormValidationState.metaKeyWordsError = true;
            } else {
                settingFormValidationState.metaKeyWordsError = false;
            }

            if (settingFormData.metaTitle === '') {
                settingFormValidationState.metaTitleError = true;
            } else {
                settingFormValidationState.metaTitleError = false;
            }

            if (settingFormData.siteTitle === '') {
                settingFormValidationState.siteTitleError = true;
            } else {
                settingFormValidationState.siteTitleError = false;
            }

            if (settingFormData.deploymentStatus === '') {
                settingFormValidationState.deploymentStatusError = true;
            } else {
                settingFormValidationState.deploymentStatusError = false;
            }

            if (!settingFormValidationState.contactNumberError && !settingFormValidationState.emailError && !settingFormValidationState.nameError && !settingFormValidationState.cookieSimilarityError && !settingFormValidationState.hitsAlertThresholdError && !settingFormValidationState.ipRateFrequencyError && !settingFormValidationState.ipRateLimitError && !settingFormValidationState.metaDescriptionError && !settingFormValidationState.emailSignatureError && !settingFormValidationState.metaKeyWordsError && !settingFormValidationState.metaTitleError && !settingFormValidationState.siteTitleError) {
                return true;
            } else {
                return false;
            }
        }

        const resetSettings = () => {
            settingFormData.contactNumber = "";
            settingFormData.email = "";
            settingFormData.name = "";
            settingFormData.gaFooter = "";
            settingFormData.gaHeader = "";
            settingFormData.hitsAlertThreshold = "";
            settingFormData.ipRateFrequency = "";
            settingFormData.ipRateLimit = "";
            settingFormData.metaDescription = "";
            settingFormData.emailSignature = "";
            settingFormData.metaKeyWords = "";
            settingFormData.metaTitle = "";
            settingFormData.paymentMethodType = false;
            settingFormData.siteTitle = "";
            settingFormData.deploymentStatus = false;
        }

        return{
            settingFormData,
            settingFormValidationState,
            updateSettings,
            resetSettings
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/settings/GeneralSettings';
</style>